.main-teaser-title {
    color: #8c8c8c;
}
#menu-btn {
    cursor: pointer;
}
.container.no-padding-top {
    padding-top: 0;
}
.container.half-padding-top {
    padding-top: 30px;
}
.footer {
    color: #777;
    position: relative;
}
.footer-text {
    bottom: 50px;
    margin-top: 20px;
}
.app-icon {
    float: left;
    border:1px solid lightgray;
    border-radius:15%;
    overflow:hidden;
    margin: 5px 15px 5px 0px;
    width: 100px;
    height: 100px;
    background-color: white;
}
.coin-icon {
    float: left;
    border:1px solid lightgray;
    border-radius:15%;
    overflow:hidden;
    margin: 5px 15px 5px 0px;
    width: 100px;
    height: 100px;
    background-color: white;
}
.adapool-btn {
    width: 150px;
    height: 50px;
    padding: 10px;
    background-color: black;
}
.coin-icon {
    float: left;
    border: 1px solid lightgray;
    border-radius: 15%;
    overflow: hidden;
    margin: 5px 15px 5px 0px;
    padding: 10px;
    width: 100px;
    height: 100px;
    background-color: white;
}
.app-icon:hover {
    text-decoration: none;
}
.table-list {
    list-style-type: none;
}
.jumbotron {
    background-color: white;
    margin-bottom: 0;
    color: black;
    border-bottom: #2B2B2B;
    border-radius: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.v-spacer-double {
    margin-top: 80px !important;
}
.v-spacer {
    margin-top: 40px;
}
.v-spacer-half {
    margin-top: 20px;
}
.col-md-4 {
    margin-top: 40px;
}
.app-spacer {
    background-color: #2B2B2B;
    color: white;
    height: 50px;
    margin-top: 50px;
    margin-bottom: 20px;
    vertical-align: middle;
    border-radius: 10px;
}
.app-spacer > h2 {
    margin-left: 20px;
    padding-top: 5px;
}
body {
    background-color: #2B2B2B;
}
.app-title {
    color: #777;
    font-size: medium;
    text-align: center;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
}
.app-title > a > img {
    float: none;
    margin: 5px;
}
.list-group-news {
    list-style: none !important;
}
.pool-stats-table {
    width: 800px;
}
.navbar-brand {
    margin-left: -48px;
    padding: 0 1rem;
}
.app-icon-textual {
    font-size: xx-large;
    color: white;
    align-items: center;
    display: flex;
    vertical-align: middle;
    text-align: center;
    margin: 5px;
    background: -webkit-linear-gradient(top, #cfe7fa 0%,#6393c1 100%);
    background: linear-gradient(to bottom, #cfe7fa 0%,#6393c1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.app-icon-textual-text {
    margin: 0 auto;
}
.app-icon-textual-wrapper {
    display: inline-block;
}
.code {
    background-color: #efefef;
    border-radius: 3px;
    padding: 10px;
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.badges > img {
    margin-right: 5px;
}
.content {
    background-color: #fafafa;
    padding-bottom: 5%;
    min-height: 87vh;
}
.main-teaser {
    background-image: -webkit-linear-gradient(bottom, #2196f3, #0E5CAD);
    background-image: linear-gradient(0deg, #2196f3, #0E5CAD);
}
.app-view {
    padding: 40px 20px 20px 20px;
    /*border: 2px solid darkslategrey;*/
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
    color: darkgray;
}
.list-group-item-date {
    color: darkgray;
}
.jumbotron > div > p {
    color: white;
    font-size: x-large;
}
.jumbotron > div > h1 {
    color: white;
}
.game-badge-image {
    margin-bottom: 10px;
}
.badge-link {
    margin-left: 5px;
}
.cardanotrees button {
    min-width: 164px;
    background-color: white;
    border: 1px solid black;
    margin: 3px;
}
.input {
    width: 50px;
    border-style: none;
}
.input-address {
    margin-left: 50px;
    border: none;
    padding: 5px 10px;
    outline: none;
    background-color: rgba(1, 1, 1, .1);
    width: 500px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);
    -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    background-color: #fff;
    padding: 10px;
 }
[placeholder]:focus::-webkit-input-placeholder {
    -webkit-transition: text-indent 0.4s 0.4s ease;
    transition: text-indent 0.4s 0.4s ease;
    text-indent: -100%;
    opacity: 1;
 }
 .treeviewer iframe {
    width: 100%;
    height: 100%;
 }
 .cardanotrees-filters {
    position: absolute;
    left: 20px;
 }
 .cardanotrees-filters ul li {
    list-style-type: none;
 }
 .treeviewer {
    text-align: center;
 }
 .treeviewer .item {
    display: inline-block;
    margin: 20px;
    background-color: coral;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    -moz-box-shadow: 0 4px 8px 0 rgba(0,0,0,.2),0 6px 20px 0 rgba(0,0,0,.19);
    -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
    background-color: #fff;
    padding: 15px;
    width: 330px;
    height: 330px;
 }
 .treeviewer .item .overlay {
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: transparent;
    z-index: 2;
    cursor: pointer;
 }
.treeviewer .detail {
    width: 700px;
    height: 700px;
}
.treeview-navigation {
    background-color: ghostwhite;
    border: 1px solid black;
    height: 99%;
    width: 100.8%;
}
 .treeviewer .item .item-text {
    color: black;
    text-align: center;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
 }
 .galery-title {
    line-height: 48px;
    color: darkslategray;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
 }
.cardanotree-table-body tr:nth-child(odd) {
}
.cardanotree-table-body tr td:nth-child(odd) {
    color: #8c8c8c;
    width: 130px;
}
.filters {
  list-style-type: none;
  padding-left: 20px;
}
.filters h4 {
    color: #8c8c8c;
}
.dashboard-widget-subtitle {
    font-size: 14px;
    margin-top: -40px;
}
.dashboard-widget-title {
    font-size: 22px;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
    font-weight: bold;
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 20px auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.standard-background {
    background-image: -webkit-linear-gradient(bottom, #2196f3, #0E5CAD);
    background-image: linear-gradient(0deg, #2196f3, #0E5CAD);
}
.yellow-background {
    background-image: -webkit-linear-gradient(bottom, #edde43, #f57f17);
    background-image: linear-gradient(0deg, #edde43, #f57f17);
}
.red-background {
    background-image: -webkit-linear-gradient(bottom, #f44336, #b71c1c);
    background-image: linear-gradient(0deg, #f44336, #b71c1c);
}
.green-background {
    background-image: -webkit-linear-gradient(bottom, #86d751, #43a047 );
    background-image: linear-gradient(0deg, #86d751, #43a047 );
}
.black-background {
    background-image: -webkit-linear-gradient(bottom, #424242, #212121 );
    background-image: linear-gradient(0deg, #424242, #212121 );
}
.dashboard-widget-row-header {
    position: absolute;
    left: 38px;
    top: 16px;
    height: 50px;
    font-weight: bold;
    color: darkslategray;
}
.dashboard-widget-row-header:hover {
    cursor: pointer;
}
.dashboard-widget-row-header-epoch-progress {
    position: absolute;
    right: 48px;
    top: 16px;
    height: 50px;
    font-weight: bold;
    color: darkgray;
}
.dashboard-widget-row-close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0px;
    top: 4px;
}
.dashboard-widget-row-close:hover {
    cursor: pointer;
}
.dashboard-widget-row {
    margin: 45px auto 0 auto;
    background-color: white; /*#e3f2fd;*/
    border-radius: 27px;
    border: 0px solid black;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-height: 250px;
    position: relative;
    display: inline-block;
    padding: 45px 30px 35px 30px;
}
.dashboard-widget-value {
    font-size: 35px;
    margin-top: -34px;
}
.dashboard-form {
    display: inline-grid;
    margin: 5px 10px;
}
.dashboard-form select {
    width: 120px;
    height: 30px;
}
.dashboard-form label {
    color: lightgray;
}
@media (min-width: 1200px) {
    .dashboard-input {
        display: inline-block;
        position: absolute;
        right: 50px;
        top: 77px;
    }
    .dashboard-widget {
        width: 190px;
        height: 190px;
        display: inline-grid;
        color: white;
        text-align: center;
        position: relative;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
        overflow: hidden;
    }
    .dashboard-widget-meter {
        margin-top: 25px;
        margin-bottom: -10px;
        font-weight: bold;
        max-width: 400px;
        position: absolute;
        left: 50%;
        top: 60%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .dashboard-input-menu-btn {
        display: none;
    }
    .dashboard-input-mobile {
        display: none;
    }
}
@media (max-width: 1199px) {
    .dashboard-input {
        display: none;
    }
    .dashboard-widget {
        width: 100%;
        height: 180px;
        display: inline-grid;
        color: white;
        text-align: center;
        position: relative;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
        overflow: hidden;
    }
    .dashboard-widget-meter {
        margin-top: 17px;
        margin-bottom: -10px;
        font-weight: bold;
        max-width: 300px;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }
    .dashboard-input-menu-btn {
        display: block;
        position: absolute;
        right: 50px;
        top: 94px;
    }
    .dashboard-input-mobile {
        display: block;
    }
}
.dashboard-header-title {
    position: absolute;
    left: 50px;
    top: 94px;
    font-size: 40px;
    margin: 10px;
}
.dashboard-header-title-text {
    display: inline-block;
    margin-top: -44px;
    position: absolute;
    width: 350px;
    top: 28px;
    left: 45px;
}
.dashboard-logo-cosmos {
    background: url(/img/cosmos_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 75px;
    height: 75px;
    position: absolute;
    top: -23px;
    left: -30px;
}
.dashboard-logo {
    background: url(/img/near_icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 75px;
    height: 75px;
    position: absolute;
    top: -23px;
    left: -30px;
}
.dashboard-logo-mina {
    background: url(/img/mina-logo.svg);
    background-repeat: no-repeat;
    background-position: center;
    width: 75px;
    height: 75px;
    position: absolute;
    top: -23px;
    left: -30px;
}
.dashboard-widget-updated {
    position: absolute;
    bottom: 10px;
    text-align: center;
    font-size: 12px;
    width: 100%;
}
.jumbotron-small {
    background-color: white;
    margin-bottom: 0;
    color: black;
    border-bottom: #2B2B2B;
    border-radius: 0;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    height: 100px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring-black div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid black;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.spinner {
    position: absolute;
    position: absolute;
    left: 50%;
    top: 57%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.near-background {
    background: url(/img/background-image-network.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}
.center-main {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.dashboard-input-menu-btn:hover {
    cursor: pointer;
}
.dashboard-input-mobile-rollout {
    width: 800px;
    padding-top: 100px;
}
.dashboard-input-mobile-head-rollout {
    -webkit-transition: height .5s ease-in-out;
    transition: height .5s ease-in-out;
    height: 200px;
    overflow: hidden;
}
.dashboard-input-mobile-head-default {
    height: 100px;
    -webkit-transition: height .5s ease-in-out;
    transition: height .5s ease-in-out;
}
.near-menu {
    margin-left: 30px;
}
.near-menu a {
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
    color: darkgray;
    font-size: 18px;
}
.near-menu a:hover {
    color: white;
    text-decoration: none;
}
.dashboard-content {
    display: grid;
}
.dashboard-header-analytics {

}
.dashboard-header-analytics a {
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
    color: darkgray;
    font-size: 18px;
}
.dashboard-header-analytics a:hover {
    color: black;
    text-decoration: none;
}
.dashboard-analytics-statistics-epoch {
    font-size: 30px;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
    color: darkgray;
}
.dashboard-analytics-statistics-epoch > ul {
    list-style: none;
    padding-top: 30px;
}
.dashboard-analytics-statistics-text {
    line-height: 130px;
    font-size: 20px;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
    color: darkgray;
}
.dashboard-header-menu {
    position: absolute;
    right: 50px;
    top: 105px;
}
.dashboard-header-analytics-environment {
    position: absolute;
    right: 50px;
    top: 80px;
}
.loading-text {
    margin-top: 80px;
    margin-left: 5px;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
}
.dashboard-header-menu a {
    margin-left: 25px;
}
.delegation-table td {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.link-bubble {
    padding: 20px;
    border-top: 2px solid black;
    color: black;
    padding: 30px 50px;
    font-family: 'Avenir Next', Helvetica, Arial, sans-serif;
    font-size: 18px;
}
.link-bubble:hover {
    text-decoration: underline !important;
    text-decoration: none !important;
    background-color: black;
    color: white;
}
a:link {
  text-decoration: none !important;
}
.sync-button:hover {
    cursor: pointer;
}
.network-scan-validator-info {
    position: absolute;
    width: 400px;
    height: 100px;
    left: 50px;
    top: 200px;
    background-color: white;
    border: 2px solid black;
}
.world-map {
    width: 70%;
    height: auto;
}
